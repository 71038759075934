'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Header = function Header() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $('.hero-account').click(function () {
      $(".sub-menu-account").toggleClass("is-active");
      $(".overlay-site").toggleClass("is-active");
    });

    $('.hero-alert').click(function () {
      $(".sub-menu-infos").toggleClass("is-active");
      $(".overlay-site").toggleClass("is-active");
    });

    $('.navigation-close').click(function () {
      $(".navigation-mobile").toggleClass("is-active");
      $(".overlay-site").toggleClass("is-active");
    });

    $('.mobile-menu-trigger').click(function () {
      $(".navigation-mobile").toggleClass("is-active");
      $(".overlay-site").toggleClass("is-active");
    });

    $(".overlay-site").click(function () {
      if ($(".overlay-site").hasClass("is-active")) {
        $(".sub-menu-account").removeClass("is-active");
        $(".navigation-mobile").removeClass("is-active");
        $(".sub-menu-infos").removeClass("is-active");
        $(".overlay-site").removeClass("is-active");
      };
    });

    $('.info-cta').click(function (e) {
      e.preventDefault();

      $(".alert").toggleClass("is-checked");
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();