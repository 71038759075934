'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.FiltersCommandes = function FiltersCommandes() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $filterMonth = $('.month-filter');
  var $filterYear = $('.year-filter');
  var $commandesWrapper = $('.resume-dashboard');
  var $baseUrl = window.location.origin;
  var currentUrl = $(location).attr('href');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($filterMonth) {
      $filterMonth.change(function () {
        var value = $(this).children("option:selected").val();

        if (value != '--none--') {

          if (currentUrl.indexOf("?y") > -1) {
            window.location.replace(currentUrl + '&m=' + value);
          } else {
            window.location.replace(currentUrl + '?m=' + value);
          }

          if (currentUrl.indexOf("?m") > -1 || currentUrl.indexOf("&m") > -1) {
            if (currentUrl.indexOf("?y") > -1) {
              window.location.replace(window.location.origin + location.pathname + '&m=' + value);
            } else {
              window.location.replace(window.location.origin + location.pathname + '?m=' + value);
            }
          }
        }
      });
    }

    if ($filterYear) {
      $filterYear.change(function () {

        var value = $(this).children("option:selected").val();

        console.log(value, currentUrl.indexOf("?m"));

        if (value != '--none--') {

          if (currentUrl.indexOf("?m") > -1) {
            window.location.replace(currentUrl + '&y=' + value);
          } else {
            window.location.replace(currentUrl + '?y=' + value);
          }

          if (currentUrl.indexOf("?y") > -1 || currentUrl.indexOf("&y") > -1) {
            if (currentUrl.indexOf("?m") > -1) {
              window.location.replace(window.location.origin + location.pathname + '&y=' + value);
            } else {
              window.location.replace(window.location.origin + location.pathname + '?y=' + value);
            }
          }
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();