'use strict';

window.site = window.site || {};

/**
 * Init Restriction Sidepanel script
 * @class Site
 * @static
 */
site.ClothesValidation = function ClothesValidation() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $qtyInputs = $('.js-qty-input');
  var $colorInputs = $('.js-color-selector');
  var isEnabledMinqty = false;
  var isColorValid = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    $colorInputs.on('click change', function (e) {
      validateOrder();
    });

    $qtyInputs.on('change keypress blur keyup', function (e) {

      validateOrder();
    });

    inited = true;

    return true;
  };

  var validateOrder = function validateOrder() {
    var $activeProduct = $('.product-wrapper.is-active');
    var formatId = $activeProduct.attr('data-product-id');
    var $qtyXS = $('.qty-xs-' + formatId).val();
    var $qtyS = $('.qty-s-' + formatId).val();
    var $qtyM = $('.qty-m-' + formatId).val();
    var $qtyL = $('.qty-l-' + formatId).val();
    var $qtyXL = $('.qty-xl-' + formatId).val();
    var $qty2XL = $('.qty-2xl-' + formatId).val();
    var $qty3XL = $('.qty-3xl-' + formatId).val();
    var $qty4XL = $('.qty-4xl-' + formatId).val();
    var $submitBtn = $('.clothes-btn-' + formatId);

    var totalQty = parseInt($qtyXS) + parseInt($qtyS) + parseInt($qtyM) + parseInt($qtyL) + parseInt($qtyXL) + parseInt($qty2XL) + parseInt($qty3XL) + parseInt($qty4XL);

    if (totalQty >= 12) {
      isEnabledMinqty = true;
    } else {
      isEnabledMinqty = false;
    }

    if ($('.is-selected.color-' + formatId).length) {
      console.log('color');
      isColorValid = true;
    } else {
      console.log('no color');
      isColorValid = false;
    }

    if (isEnabledMinqty && isColorValid) {
      $submitBtn.prop('disabled', false);
    } else {
      $submitBtn.prop('disabled', true);
    }
  };

  /*var validateQty = function(qtyInput, type) {
     if(parseInt(qtyInput) >= 1 && parseInt(qtyInput <= 11)) {
      console.log(type + " " + parseInt(qtyInput)  + " : false")
      return false;
    }
     console.log(type + + " " + parseInt(qtyInput)  + " : true")
    return true;
  };*/

  // Expose public methods & properties
  return {
    init: init
  };
}();