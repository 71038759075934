"use strict";

window.site = window.site || {};
/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */

site.createCart = function createCart() {
  'use strict';
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $selectDiscipline = $('[action-select-discipline]');
  var $sportsIcon = $('[action-sports-icon]');
  var $submitCart = $('.submit-btn');
  var $redirectInput = $('[action-redirect-value]');
  var $numberInput = $('[action-required-item]');
  var $selectAllCats = $('#allCats');
  var modifMed = $('.modif-medailles');
  var $medOrLocRub = $('.or-avec-ruban-locale');
  var $medArgLocRub = $('.argent-avec-ruban-locale');
  var $medBrnzLocRub = $('.bronze-avec-ruban-locale');
  var $medOrRegRub = $('.or-avec-ruban-regionale');
  var $medArgRegRub = $('.argent-avec-ruban-regionale');
  var $medBrnzRegRub = $('.bronze-avec-ruban-regionale');
  var $medOrProvRub = $('.or-avec-ruban-provinciale');
  var $medArgProvRub = $('.argent-avec-ruban-provinciale');
  var $medBrnzProvRub = $('.bronze-avec-ruban-provinciale');
  var $medOrLoc = $('.or-sans-ruban-local');
  var $medArgLoc = $('.argent-sans-ruban-locale');
  var $medBrnzLoc = $('.bronze-sans-ruban-locale');
  var $medOrReg = $('.or-sans-ruban-regional');
  var $medArgReg = $('.argent-sans-ruban-regionale');
  var $medBrnzReg = $('.bronze-sans-ruban-regionale');
  var $rubOnly = $('.ruban-seulement');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    if ($selectAllCats) {
      $selectAllCats.on('change', function (i) {
        console.log($selectAllCats.val());

        if ($selectAllCats.val() != '2010' || $selectAllCats.val() != '2914' || $selectAllCats.val() != '2011' || $selectAllCats.val() != '2822' || $selectAllCats.val() != '2012') {
          $('.format-wrapper').removeClass('is-dead');
        } else {
          $('.format-wrapper').addClass('is-dead');
        } // Locale


        if ($selectAllCats.val() === '2010') {
          $medOrLocRub.removeClass('is-dead');
          $medArgLocRub.removeClass('is-dead');
          $medBrnzLocRub.removeClass('is-dead');
          $medOrLoc.removeClass('is-dead');
          $medArgLoc.removeClass('is-dead');
          $medBrnzLoc.removeClass('is-dead');
          $rubOnly.removeClass('is-dead');
        } // Regionale G


        if ($selectAllCats.val() === '2914') {
          $medOrRegRub.removeClass('is-dead');
          $medArgRegRub.removeClass('is-dead');
          $medBrnzRegRub.removeClass('is-dead');
          $medOrReg.removeClass('is-dead');
          $medArgReg.removeClass('is-dead');
          $medBrnzReg.removeClass('is-dead');
          $rubOnly.removeClass('is-dead');
        } // Regionale FR


        if ($selectAllCats.val() === '2011') {
          $medOrRegRub.removeClass('is-dead');
          $medArgRegRub.removeClass('is-dead');
          $medBrnzRegRub.removeClass('is-dead');
          $medOrReg.removeClass('is-dead');
          $medArgReg.removeClass('is-dead');
          $medBrnzReg.removeClass('is-dead');
          $rubOnly.removeClass('is-dead');
        } // Regionale EN


        if ($selectAllCats.val() === '2822') {
          $medOrRegRub.removeClass('is-dead');
          $medArgRegRub.removeClass('is-dead');
          $medBrnzRegRub.removeClass('is-dead');
          $medOrReg.removeClass('is-dead');
          $medArgReg.removeClass('is-dead');
          $medBrnzReg.removeClass('is-dead');
          $rubOnly.removeClass('is-dead');
        } // Provinciale


        if ($selectAllCats.val() === '2012') {
          $medOrProvRub.removeClass('is-dead');
          $medArgProvRub.removeClass('is-dead');
          $medBrnzProvRub.removeClass('is-dead');
          $rubOnly.removeClass('is-dead');
        }
      });
    }

    if ($('.modif-form')) {
      modifMed.each(function () {
        var masterElem = $(this);
        $(this).find('[action-sports-icon-modify]').hide();
        $(this).find('[action-select-discipline-modify]').on('change', function (e) {
          var sportIndex = $(this).find(':selected').data('img-index');
          masterElem.find('[action-sports-icon-modify]').hide();
          masterElem.find('.default-icon').hide();
          masterElem.find('.discipline-' + sportIndex).show();
        });
      });
    }

    $selectDiscipline.on('change', function (e) {
      var sportIndex = $(this).find(':selected').data('img-index');
      $sportsIcon.hide();
      $('.discipline-' + sportIndex).show();
    });
    $numberInput.on('keyup', function (e) {
      if (validateItems()) {
        $('a[action-toggle-btn]').addClass('is-hidden');
        $('button[action-toggle-btn]').removeClass('is-hidden');
      } else {
        $('a[action-toggle-btn]').removeClass('is-hidden');
        $('button[action-toggle-btn]').addClass('is-hidden');
      }
    });
    $submitCart.on('click', function (e) {
      if (!validateItems() && validateItems()) {
        console.log('test');
      } else if (validateItems()) {
        console.log('test 2');
        var redirectValue = $(this).data('redirect-value');
        $redirectInput.val(redirectValue);
      } else {
        console.log('test 3');
        $('.error-message').show();

        if ($('.step-two').length) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $('.step-two').offset().top - 20
          }, 500);
        } else if ($('.format-selected').length) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $('.format-selected').offset().top - 20
          }, 500);
        }

        return false;
      }
    });
    inited = true;
    return true;
  };

  var validateItems = function validateItems() {
    var totalItems = 0;
    $numberInput.each(function (e) {
      totalItems = totalItems + $(this).val();
    });

    if (totalItems == 0) {
      return false;
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();