'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.clothesCart = function clothesCart() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $clothesTypes = $('.format-wrapper');
  var $colorClothes = $('.color-checkbox');
  var $fileInput = $('.js-file-input'),
      $fileName = $('.js-file-name');
  var $tooltip = $('.js-tooltip-qty');
  var $frontClothes = $('.js-clothes-front'),
      $backClothes = $('.js-clothes-back'),
      $mainClothe = $('.js-clothes-change');
  var $deleteBtn = $('.js-delete-clothes');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    $deleteBtn.on('click', function (e) {
      e.preventDefault();
      var currentIndex = $(this).attr('data-clothes-index');
      $('.remove-' + currentIndex).prop('disabled', false);
      $('.add-clothes-' + currentIndex).prop('disabled', true);
      $('.clothes-form').submit();
    });

    $frontClothes.on('click', function (e) {
      if ($(this).attr('data-product-id')) {
        var productId = $(this).attr('data-product-id');

        $('.image-' + productId).attr('src', $(this).attr('data-clothes-img'));
        $(this).addClass('is-active');
        $backClothes.removeClass('is-active');
      } else {
        $mainClothe.attr('src', $(this).attr('data-clothes-img'));
        $(this).addClass('is-active');
        $backClothes.removeClass('is-active');
      }
    });

    $backClothes.on('click', function (e) {
      if ($(this).attr('data-product-id')) {
        var productId = $(this).attr('data-product-id');

        $('.image-' + productId).attr('src', $(this).attr('data-clothes-img'));
        $(this).addClass('is-active');
        $frontClothes.removeClass('is-active');
      } else {
        $mainClothe.attr('src', $(this).attr('data-clothes-img'));
        $(this).addClass('is-active');
        $frontClothes.removeClass('is-active');
      }
    });

    $fileInput.on('change', function (e) {

      var fullPath = $(this).val();
      if (fullPath) {
        var startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/');
        var filename = fullPath.substring(startIndex);
        if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
          filename = filename.substring(1);
        }

        $fileName.text(filename);
      }
    });

    $clothesTypes.on('click', function (i) {
      i.preventDefault();

      var formatId = $(this).attr('data-format-id');
      var $product = $('.product-' + formatId);
      var $disableInputs = $('.disabled-input');
      var $productBox = $('.product-wrapper');
      var $tbSelector = $('.clothes-selector-wrapper');
      var $submitBtn = $('.ind-submit');

      $productBox.removeClass('is-active');
      $tbSelector.removeClass('is-active');
      $disableInputs.prop('disabled', true);
      $submitBtn.prop('disabled', true);

      if ($productBox.hasClass('product-' + formatId)) {
        $product.addClass('is-active');
      }

      if ($disableInputs.hasClass('input-' + formatId)) {
        $('.input-' + formatId).prop('disabled', false);
      }

      $([document.documentElement, document.body]).animate({
        scrollTop: $product.offset().top - 20
      }, 500);
    });

    $('.format-wrapper .to-cart-btn').on('click', function (e) {
      e.stopPropagation();
    });

    $colorClothes.on('click', function (i) {

      var radioInput = $(this).find('input[type="radio"]');

      $colorClothes.removeClass('is-selected');

      radioInput.attr('checked', 'checked');

      $(this).addClass('is-selected');
    });

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();