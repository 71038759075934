'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.bannersCreateOrder = function bannersCreateOrder() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var createOrderPage = document.querySelector('.banners-create-wrapper');

  /**
   * Update price for banners order.
   */
  var updatePrice = function updatePrice() {
    // Get prices grid
    var $priceGrid = document.getElementById('price-grid');

    // Get banners format
    var $formatInput = createOrderPage.querySelector('select[data-format]');
    var formatId = parseInt($formatInput.value, 10);

    if ($priceGrid && formatId) {
      var prices = JSON.parse($priceGrid.dataset.priceGrid);

      // Get banners price
      var itemPrice = prices.find(function (el) {
        return el.id === formatId;
      }).price;

      // Calculate total price
      var price = 0;
      createOrderPage.querySelectorAll('.product-items .product-item').forEach(function ($item) {
        // Get quantity
        var $quantityInput = $item.querySelector('.form-input[data-quantity]');
        var quantity = $quantityInput.value.length ? parseInt($quantityInput.value, 10) : 0;

        price += quantity * itemPrice;
      });
      price = parseFloat(price, 10).toFixed(2);

      // Update price text
      var $priceSpan = createOrderPage.querySelector('[data-price-text]');
      $priceSpan.textContent = price;

      // Update price input
      var $priceInput = createOrderPage.querySelector('[data-input-price]');
      $priceInput.value = price;
      var $priceTotalInput = createOrderPage.querySelector('[data-input-price-taxes]');
      var totalPrice = parseFloat(price * 1.14975, 10).toFixed(2);
      $priceTotalInput.value = totalPrice;
    }
  };

  /**
   * Watch quantity input values to update price.
   */
  var watchQuantityValues = function watchQuantityValues() {
    var $quantityInputs = createOrderPage.querySelectorAll('.form-input[data-quantity]');
    $quantityInputs.forEach(function ($quantityInput) {
      $quantityInput.addEventListener('change', updatePrice);
    });
  };

  /**
   * Watch quantity input values to update price.
   */
  var watchFormatValue = function watchFormatValue() {
    var $formatSelect = createOrderPage.querySelector('.form-select[data-format]');
    $formatSelect.addEventListener('change', updatePrice);
  };

  /**
   * Init sector input values to update other values.
   */
  var initSectorValue = function initSectorValue() {
    var $sectorSelect = createOrderPage.querySelector('.form-select[data-sector]');
    if ($sectorSelect.value !== 'Secteur') {
      var sectorValue = $sectorSelect.options[$sectorSelect.selectedIndex].textContent;

      var $selectFields = document.querySelectorAll('.form-select[data-dynamic-sector]');
      if ($selectFields.length) {
        $selectFields.forEach(function ($selectField) {
          // Remove unwanted options
          var allOptions = JSON.parse($selectField.dataset.all);
          var byLevel = JSON.parse($selectField.dataset.byLevel);

          var optionsIds = Object.values(allOptions);
          var levelOptionsIds = byLevel[sectorValue].map(function (el) {
            return parseInt(el, 10);
          });

          var goodOptions = optionsIds.filter(function (el) {
            return levelOptionsIds.includes(el);
          });
          Object.entries(allOptions).forEach(function (entry) {
            var _entry = _slicedToArray(entry, 2),
                key = _entry[0],
                value = _entry[1];

            if (!goodOptions.includes(value)) {
              var $unwantedOption = $selectField.querySelector('option[value="' + value + '"]');
              $unwantedOption.remove();
            }
          });
        });
      }
    }
  };

  /**
   * Watch sector input values to update other values.
   */
  var watchSectorValue = function watchSectorValue() {
    var $sectorSelect = createOrderPage.querySelector('.form-select[data-sector]');
    $sectorSelect.addEventListener('change', function () {
      var sectorValue = $sectorSelect.options[$sectorSelect.selectedIndex].textContent;

      var $selectFields = document.querySelectorAll('.form-select[data-dynamic-sector]');
      if ($selectFields.length) {
        $selectFields.forEach(function ($selectField) {
          var initialLabel = $selectField.options[0].textContent;
          // Remove all options
          $selectField.innerHTML = '';
          $selectField.innerHTML = '<option selected="" disabled="">' + initialLabel + '</option>';

          // Reconstruct options
          var allOptions = JSON.parse($selectField.dataset.all);
          var byLevel = JSON.parse($selectField.dataset.byLevel);

          var optionsIds = Object.values(allOptions);
          var levelOptionsIds = byLevel[sectorValue].map(function (el) {
            return parseInt(el, 10);
          });

          var goodOptions = optionsIds.filter(function (el) {
            return levelOptionsIds.includes(el);
          });
          Object.entries(allOptions).forEach(function (entry) {
            var _entry2 = _slicedToArray(entry, 2),
                key = _entry2[0],
                value = _entry2[1];

            if (goodOptions.includes(value)) {
              $selectField.innerHTML += '<option value="' + value + '">' + key + '</option>';
            }
          });
        });
      }
    });
  };

  /**
   * Add a new set of inputs for banners order.
   */
  var checkDeleteButtons = function checkDeleteButtons() {
    var $productSets = createOrderPage.querySelectorAll('.product-items .product-item');
    if ($productSets.length > 1) {
      // Enable first delete link
      var allDeleteButtons = createOrderPage.querySelectorAll('.delete-button-section');
      allDeleteButtons.forEach(function (allDeleteButton) {
        allDeleteButton.classList.remove('is-hidden');
      });
    } else if ($productSets.length === 1) {
      // Disable first delete link
      var firstDeleteButtonContainer = $productSets[0].querySelector('.delete-button-section');
      firstDeleteButtonContainer.classList.add('is-hidden');
    }
    deleteProduct();
  };

  /**
   * Add a new set of inputs for banners order.
   */
  var addProduct = function addProduct() {
    var $addButton = createOrderPage.querySelector('[data-add-new-item]');
    if ($addButton) {
      $addButton.addEventListener('click', function () {
        var $firstSet = createOrderPage.querySelector('.product-item.is-template');
        var $newSet = $firstSet.cloneNode(true);

        // Find next index
        var nextName = null;
        var newIndex = 0;
        var $productSets = createOrderPage.querySelectorAll('.product-items .product-item');

        while (!nextName) {
          newIndex += 1;
          var found = false;
          // eslint-disable-next-line no-loop-func
          $productSets.forEach(function ($productSet) {
            var $firstInput = $productSet.querySelector('.form-input');

            var inputName = $firstInput.getAttribute('name');
            if (inputName.search('new' + newIndex) >= 0) {
              found = true;
            }
          });

          if (!found) {
            nextName = 'new' + newIndex;
          }
        }

        // Update inputs names on new set
        $newSet.innerHTML = $newSet.innerHTML.replaceAll('new1', nextName);
        $newSet.classList.remove('is-template');

        // Add new input set
        var $setContainer = createOrderPage.querySelector('.product-items');
        $setContainer.append($newSet);
        checkDeleteButtons();
        watchQuantityValues();
      });
    }
  };

  /**
   * Delete a set of inputs for banners order.
   */
  var deleteProduct = function deleteProduct() {
    var $deleteButtons = createOrderPage.querySelectorAll('[data-delete]');
    if ($deleteButtons.length) {
      $deleteButtons.forEach(function ($deleteButton) {
        $deleteButton.addEventListener('click', function () {
          var $productSet = $deleteButton.closest('.product-item');
          $productSet.remove();
          checkDeleteButtons();
          updatePrice();
        });
      });
    }
  };

  /**
   * Submit form for banners order.
   */
  var submitForm = function submitForm() {
    var $submitButton = createOrderPage.querySelector('[data-submit-form]');
    if ($submitButton) {
      $submitButton.addEventListener('click', function () {
        var $template = createOrderPage.querySelector('.product-item.is-template');
        if ($template) {
          $template.remove();
        }
        var $form = createOrderPage.querySelector('#banners-order-form');
        $form.submit();
      });
    }
  };

  /**
   * Toggle instructions for banners order.
   */
  var toggleInstructions = function toggleInstructions() {
    var $instructionsButton = createOrderPage.querySelector('[data-instructions-toggle]');
    if ($instructionsButton) {
      $instructionsButton.addEventListener('click', function () {
        var $instructionSection = createOrderPage.querySelector('.banners-instructions');
        $instructionSection.classList.toggle('is-active');
      });
    }
  };

  /**
   * Scroll to a specific item.
   */
  var scrollToItem = function scrollToItem() {
    var param = window.location.search.substr(1);

    if (param) {
      if (param.search('show-item') >= 0) {
        var itemId = param.split('=')[1];
        var $item = document.getElementById('item-' + itemId);

        setTimeout(function () {
          $item.scrollIntoView();
        }, 1000);
      } else if (param.search('add-item') >= 0) {
        setTimeout(function () {
          var $addButton = createOrderPage.querySelector('[data-add-new-item]');
          $addButton.click();
          var $items = document.querySelectorAll('.product-items .product-item');
          var $item = $items[$items.length - 1];
          $item.scrollIntoView();
        }, 1000);
      }
    }
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    if (createOrderPage) {
      checkDeleteButtons();
      addProduct();
      deleteProduct();
      watchQuantityValues();
      watchFormatValue();
      updatePrice();
      submitForm();
      toggleInstructions();
      scrollToItem();
      initSectorValue();
      watchSectorValue();
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();