'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.LockedAlert = function LockedAlert() {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */

    var inited = false;
    var $lockedCommandes = $('[data-alert-locked]');

    /**
     * Initializes the class.
     * @public
     */
    var init = function init() {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        $lockedCommandes.each(function (index) {
            $(this).on("click", function () {
                swal({
                    text: "Votre commande ne plus être modifiée, elle est déjà en cours de préparation.",
                    icon: "/img/icons/locker-icon.png",
                    button: {
                        text: 'Merci, c’est compris!',
                        position: 'center'
                    }
                });
            });
        });

        return true;
    };

    // Expose public methods & properties
    return {
        init: init
    };
}();