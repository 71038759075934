'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.AccordionModify = function AccordionModify() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $articles = $('.is-accordeon');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $articles.each(function (index) {
      var $step = $(this).children('.form-steps');
      var $opener = $(this).children('.open-accordion');

      $step.hide();

      $opener.on('click', function (e) {

        $(this).children('img').toggleClass('rotate');

        if ($step.is(":hidden")) {
          console.log(' if hidden');
          $('.form-steps').hide();
          $step.show();
        } else {
          if ($step.is(":visible")) {
            console.log('visible');
            $('.form-steps').hide();
          }
        }
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();