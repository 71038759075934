"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.MedalFilter = function MedalFilter() {
  "use strict";

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var levelSelected = document.querySelector("#allCats");

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    levelSelected.addEventListener("change", function (e) {
      var allMedals = document.querySelectorAll(".type-medal");
      var medalsSelected = document.querySelectorAll([".display-" + e.target.value]);
      var medalsAlwaysSelected = document.querySelector(".display-");
      allMedals.forEach(function (element) {
        element.style.display = "none";
      });
      medalsSelected.forEach(function (element) {
        element.style.display = "inline-block";
      });
      medalsAlwaysSelected.style.display = "inline-block";
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();