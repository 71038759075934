'use strict';

window.site = window.site || {};

/**
 * Init Restriction Sidepanel script
 * @class Site
 * @static
 */
site.RestrictionPopup = function RestrictionPopup() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $warningClose = $('.js-close-warning'),
      $warningBox = $('.js-restriction-warning'),
      $popupTrigger = $('.js-restriction-trigger');

  var $restrictionPopup = $('.js-restriction-popup'),
      $popupClose = $('.js-close-restriction'),
      $overlay = $('.restriction-overlay');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    $warningClose.on('click', function (e) {
      $warningBox.hide();
    });

    $popupTrigger.on('click', function (e) {
      $restrictionPopup.addClass('is-opened');
      $overlay.addClass('is-opened');
    });

    $popupClose.on('click', function (e) {
      e.preventDefault();
      $restrictionPopup.removeClass('is-opened');
      $overlay.removeClass('is-opened');
    });

    $overlay.on('click', function (e) {
      $restrictionPopup.removeClass('is-opened');
      $overlay.removeClass('is-opened');
    });

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();