"use strict";

window.site = window.site || {};
/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */

site.FormValidation = function FormValidation() {
  'use strict';
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var selectDiscipline = $('.select-disciplines');
  var selectCategory = $('.select-category');
  var selectCouleur = $('.select-couleur');
  var addLogoMask = $('.add-logo');
  var selectPartners = $('.select-partenaires');
  var selectPartnersModify = $('.modify-select-partners');
  var setTwo = $('.step-two');
  var setThree = $('.step-three');
  var valueCat = '0';
  var valueDis = '0';
  var $baseUrl = window.location.origin;
  var currentUrl = $(location).attr('href');
  var openCart = $('[action-open-cart]');
  var resumeCart = $('.is-modal');
  var overlay = $('.total-overlay');
  var handle = '';
  var index = '';
  var $requiredInputs = $('.vetements-form [action-required]');
  var dataRedirectId = $('[data-redirect-value]');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (selectPartnersModify) {
      selectPartnersModify.each(function (e) {
        var thisSelect = $(this).children('.select-disciplines');

        if (thisSelect.children("option:selected").val() === 'autre') {
          var hiddenPartner = $(this).next('.hidden-partner');
          hiddenPartner.show();
        } else {
          $(this).next('.hidden-partner-modify').hide();
        }

        thisSelect.on('change', function (e) {
          valueDis = thisSelect.children("option:selected").val();

          if (valueDis === 'autre') {
            $('.hidden-partner-modify').show();
          } else {
            $('.hidden-partner-modify').hide();
          }
        });
      });
    }

    $('.hidden-partner').hide();
    setTwo.hide();
    setThree.hide();
    resumeCart.hide();
    overlay.hide();
    openCart.on('click', function (e) {
      if (resumeCart.is(":visible")) {
        resumeCart.hide();
      } else {
        resumeCart.show();
        overlay.show();
      }
    });
    overlay.on('click', function (e) {
      resumeCart.hide();
      overlay.hide();
    });
    selectCouleur.on('change', function (e) {
      valueCat = $(this).children("option:selected").val();

      if (valueCat != '0') {
        setTwo.show();
      } else {
        setTwo.hide();
      }
    });
    selectCategory.on('change', function (e) {
      valueCat = $(this).children("option:selected").val();

      if (valueCat != '0') {
        selectDiscipline.focus().select();
      }

      if (valueDis != '0' && valueCat != '0') {
        setTwo.show();
      } else {
        setTwo.hide();
      }
    });
    selectDiscipline.on('change', function (e) {
      valueDis = $(this).children("option:selected").val();
      console.log({
        valueCat: valueCat,
        valueDis: valueDis
      });

      if (valueDis != '0') {
        selectCategory.focus().select();
      }

      if (valueDis != '0' && valueCat != '0') {
        setTwo.show();
      } else {
        setTwo.hide();
      }
    });
    addLogoMask.on('change', function (e) {
      setThree.show();
    });
    selectPartners.on('change', function (e) {
      valueDis = $(this).children("option:selected").val();

      if (valueDis != 'None') {
        setThree.show();
      } else {
        setThree.hide();
      }

      if (valueDis === 'autre') {
        setThree.hide();
        $('.hidden-partner').show();
        $('.logo-change').change(function (e) {
          setThree.show();
        });
      } else {
        $('.hidden-partner').hide();
      }
    });
    $(".saveFormContinue").click(function (e) {
      console.log('triggered this');
      e.preventDefault();
      var form = $("#main-form");
      var url = form.find('input[name="action"]').val();
      var formData = new FormData($("#main-form")[0]);
      $.ajax({
        type: "POST",
        url: url,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'json',
        success: function success() {
          location.reload();
        },
        error: function error() {
          swal({
            title: 'Désolé, une erreur est survenue.',
            icon: "error",
            button: 'Continuer'
          });
        }
      });
    });
    $(".saveForm").click(function (e) {
      e.preventDefault();
      var form = $("#main-form");
      var url = form.find('input[name="action"]').val();
      console.log(dataRedirectId);
      var $statusInput = form.find('#status-input');

      if ($statusInput) {
        $statusInput.val('133920');
      }

      var formData = new FormData($("#main-form")[0]);
      $.ajax({
        type: "POST",
        url: url,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'json',
        success: function success() {
          window.location.replace($baseUrl + '/merci?commande=' + dataRedirectId.data('redirect-value'));
        },
        error: function error() {
          swal({
            title: 'Désolé, une erreur est survenue.',
            icon: "error",
            button: 'Continuer'
          });
        }
      });
    });
    $(".modifyForm").click(function (e) {
      e.preventDefault();
      var form = $("#main-form");
      var url = form.find('input[name="action"]').val();
      var formData = new FormData($("#main-form")[0]);
      $.ajax({
        type: "POST",
        url: url,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'json',
        success: function success() {
          window.location.replace($baseUrl + '/merci?commande=' + dataRedirectId);
        },
        error: function error() {
          swal({
            title: 'Désolé, une erreur est survenue.',
            icon: "error",
            button: 'Continuer'
          });
        }
      });
    });
    var formIsValid = false;
    $requiredInputs.each(function () {
      $(this).on('change keyup keypress blur', function () {
        formIsValid = validateForm($requiredInputs);
        $('[action-validate]').prop('disabled', !formIsValid);
      });
    });
    return true;
  };

  var validateForm = function validateForm(field) {
    var isValid = true;
    field.each(function () {
      if ($(this).val() === '' || $(this).val() === null) isValid = false;
    });
    return isValid;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();