'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.CalculPrice = function CalculPrice() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var valueCat = '0';
  var valueDis = '0';

  var openCart = $('[action-open-cart]');
  var resumeCart = $('.is-modal');
  var overlay = $('.total-overlay');
  var inputPrices = $('[data-medal-price]');
  var oldPrice = $('#old-price');
  var newPrice = $('#current-price');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    inputPrices.each(function (index) {
      var typeInput = $(this);
      $(this).on('change', function (e) {
        var currentPrice = 0;

        var $medalVal = parseFloat(Math.ceil($(this).val() / 10) * 10);

        if (typeInput.attr('input-mask')) {
          console.log('input mask');

          if ($(this).val() !== 0) {

            if ($(this).val() <= 500 && $(this).val() >= 1) {

              $medalVal = 500;
            }
          } else {
            $medalVal = 0;
          }
        }

        $(this).val($medalVal);

        var $dataPrice = parseFloat($(this).data('medal-price'));
        var $dataOldVal = parseFloat($(this).data('old-value'));
        var newValue = $(this).next('.new-value');

        console.log('toto', $medalVal);

        // Modify commandes script
        if ($dataOldVal) {
          console.log('Modify');

          if (newValue.val() >= 0) {
            $dataOldVal = newValue.val();
          }

          console.log('newValue 1', newValue.val());
          console.log('$dataOldVal', $dataOldVal);

          currentPrice -= parseFloat($dataOldVal) * parseFloat($dataPrice);

          currentPrice += parseFloat($medalVal) * parseFloat($dataPrice);

          newValue.val($(this).val());

          console.log('newValue 2', newValue.val());
        }
        // First Commande script
        else {
            console.log('Commande');
            if ($(this).val() >= 0) {

              if (newValue.val() == 0) {

                currentPrice += parseFloat($medalVal) * parseFloat($dataPrice);

                newValue.val($(this).val());
              } else {

                if (newValue.val() > $medalVal) {

                  currentPrice -= (parseFloat(newValue.val()) - parseFloat($medalVal)) * parseFloat($dataPrice);
                  newValue.val($(this).val());
                } else {

                  currentPrice -= parseFloat(newValue.val()) * parseFloat($dataPrice);

                  currentPrice += parseFloat($medalVal) * parseFloat($dataPrice);
                  newValue.val($(this).val());
                }
              }
            }
          }

        if (parseFloat(oldPrice.val()) === 0) {

          oldPrice.val(parseFloat(currentPrice));
        } else {

          oldPrice.val(parseFloat(oldPrice.val()) + parseFloat(currentPrice));

          currentPrice = parseFloat(oldPrice.val());
        }

        // if (currentPrice < 0) {
        //   currentPrice = 0;
        // }

        // currentPrice = parseFloat(currentPrice) * 1.14975;

        currentPrice = parseFloat(currentPrice.toFixed(2));

        $('[ action-cart-total]').html(parseFloat(currentPrice));

        newPrice.val(parseFloat(currentPrice));
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();