'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.AjaxPagination = function AjaxPagination() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $paginations = $('[data-ajax-pagination]');
  var $length = document.querySelector('[data-commandes-length]');

  var $oneCommandeOnly = document.querySelector('[action-one-commande]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($oneCommandeOnly) {
      $oneCommandeOnly.addEventListener('click', function (e) {
        e.preventDefault();
        swal({
          title: 'Désolé, vous avez déjà une commande. Veuillez modifier cette commande ou contacter le référent.',
          icon: "error",
          button: 'Continuer'
        });
      });
    }

    if ($length > 9) {
      $paginations.addEventListener('click', function (e) {

        e.preventDefault();

        if (!e.target.matches('a') && !e.target.matches('span')) {
          return;
        }

        var $page = null;

        if (e.target.matches('span')) {
          $page = e.target.parentNode;
        } else {
          $page = e.target;
        }

        if (!$page) {
          return;
        }

        var $pagination = $page.parentNode.parentNode;
        var zone = $pagination.dataset.ajaxPagination;
        var $ajaxZone = document.querySelector('[data-ajax-paginate="' + zone + '"]');
        var $test = $page.closest('[data-ajax-paginate]');

        var $loader = '<div class="loader-container" style="height: ' + $ajaxZone.clientHeight + 'px;"><div class="loader"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div></div>';
        var apiURL = $page.getAttribute('href');

        if (zone == 'places') {
          document.querySelector('.right-section').scrollTo({
            top: $ajaxZone.getBoundingClientRect().top,
            behavior: "smooth"
          });

          window.scrollTo({
            top: $ajaxZone.getBoundingClientRect().top,
            behavior: "smooth"
          });

          var $BlockOffers = document.querySelector('[data-places-offers]');

          if ($BlockOffers) {

            if (parseInt($page.textContent) > 1) {
              $BlockOffers.style.display = 'none';
            } else {
              $BlockOffers.style.display = 'block';
            }
          }
        } else {

          window.scrollTo({
            top: window.pageYOffset - $ajaxZone.offsetHeight,
            behavior: "smooth"
          });
        }

        $ajaxZone.innerHTML = $loader;

        fetch(apiURL, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'GET'
        }).then(function (resp) {
          return resp.text();
        }).then(function (data) {

          if (data) {

            // Fetch and transform response to an HTML object
            var htmlResponse = document.createElement('div');
            htmlResponse.innerHTML = data;

            var $refreshArticle = htmlResponse.querySelector('[data-ajax-paginate="' + $pagination.getAttribute('data-ajax-pagination') + '"]').innerHTML;
            $ajaxZone.innerHTML = $refreshArticle;

            $pagination.innerHTML = htmlResponse.querySelector('[data-ajax-pagination="' + $pagination.getAttribute('data-ajax-pagination') + '"]').innerHTML;

            if (zone == 'places') {
              site.PlacesMap.initMap();
            }
          }
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();